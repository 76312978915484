import { colors, mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Section = styled.section`
  background-color: ${colors.teal1};
  padding: ${size.xl3}px ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.xl3}px ${size.xl6}px;
  }

  h3 {
    ${typography.overlineText};
    margin-bottom: ${size.sm}px;
    text-transform: uppercase;
    font-weight: ${typography.weight.xbold};
    text-align: center;
  }

  h4 {
    ${typography.h3Text}
    font-weight: normal !important;
    margin-bottom: ${size.lg}px;
    text-align: center;
    ${mediaQueries.forTabletHorizontalUp} {
      margin: 0 auto;
    }
  }
`;

export const Description = styled.p`
  ${typography.bodyText}
  margin-top: ${size.md}px;
  text-align: center;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${size.xl1}px;
  justify-content: center;

  /* On desktop reverse the order of the items */
  ${mediaQueries.forDesktopUp} {
    flex-direction: row-reverse;
    flex: 0 0 auto;
    gap: ${size.xl2}px;
    padding: 0 ${size.xl4}px;

    > div {
      flex: 1;
    }

    > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const Content = styled.div`
  margin-bottom: ${size.md}px;
  p:last-child {
    ${typography.bodyTextSmall}
  }
`;

export const ImageContainer = styled.div`
  margin-top: ${size.xl1}px;

  .desktop-image {
    display: none;
  }

  ${mediaQueries.forDesktopUp} {
    .desktop-image {
      display: block;
      max-width: 600px;
      margin: 0 auto;
    }

    .mobile-image {
      display: none;
    }
  }
`;
