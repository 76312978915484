import { Container, H3, H4, TertiaryLink } from '@everlywell/leaves';
import TrackVisibilityWrapper from 'components/TrackVisibilityWrapper';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ImageType } from 'utils/types';
import ComparisonListModal, { ComparisonList } from '../ComparisonListModal';

import * as S from './UnderstandingTheTestContainer.styles';

export type UnderstandingTheTestContainerProps = {
  content?: {
    title: string;
    headline: string;
    modalButtonText?: string;
    description: {
      description: string;
    };
    mobileImages?: ImageType[];
    desktopImages?: ImageType[];
    listOfContentSections: Array<{
      title: string;
      description: {
        description: string;
      };
    }>;
    comparisonList?: ComparisonList;
  };
};

export const OPEN_MODAL_BUTTON_TEST_ID = 'open-modal-button';

const UnderstandingTheTestContainer = ({
  content,
}: UnderstandingTheTestContainerProps): JSX.Element | null => {
  if (!content) {
    return null;
  }

  const [isComparisonModalOpen, setIsComparisonModalOpen] =
    useState<boolean>(false);

  const {
    title,
    headline,
    description: { description },
    listOfContentSections,
    modalButtonText,
    comparisonList,
    desktopImages,
    mobileImages,
  } = content;

  const onModalOpen = () => {
    setIsComparisonModalOpen(true);
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_MODAL,
      data: {
        label: `${modalButtonText} - ${title}`,
      },
    });
  };

  return (
    <S.Section>
      <TrackVisibilityWrapper
        label={ANALYTICS.PDP.UNDERSTANDING_SECTION}
        slug={title}
      >
        <Container>
          <H3>{title}</H3>
          <H4>{headline}</H4>
          <S.Description>{description}</S.Description>

          <S.Details>
            <div>
              {listOfContentSections.map(
                ({ title, description: { description } }) => (
                  <S.Content key={title}>
                    <p>{title}</p>
                    <p>{description}</p>
                  </S.Content>
                ),
              )}
              {modalButtonText ? (
                <TertiaryLink data-testid={OPEN_MODAL_BUTTON_TEST_ID} onClick={() => onModalOpen()}>
                  {modalButtonText}
                </TertiaryLink>
              ) : null}
            </div>

            <S.ImageContainer>
              {mobileImages && mobileImages[0] ? (
                <div>
                  <GatsbyImage
                    className="mobile-image"
                    image={mobileImages[0].gatsbyImageData}
                    alt={mobileImages[0].description || ''}
                  />
                </div>
              ) : null}

              {desktopImages && desktopImages[0] ? (
                <div>
                  <GatsbyImage
                    className="desktop-image"
                    image={desktopImages[0].gatsbyImageData}
                    alt={desktopImages[0].description || ''}
                  />
                </div>
              ) : null}
            </S.ImageContainer>
          </S.Details>
        </Container>
      </TrackVisibilityWrapper>

      {comparisonList ? (
        <ComparisonListModal
          isModalOpen={isComparisonModalOpen}
          setIsModalOpen={setIsComparisonModalOpen}
          comparisonList={comparisonList}
        />
      ) : null}
    </S.Section>
  );
};

export default UnderstandingTheTestContainer;
